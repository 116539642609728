import React from "react"
import {useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "../components/css/contact.scss"
import { MdOutlineLocationOn } from "react-icons/md"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter, FaYoutube } from "react-icons/fa"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { BsArrowUp } from "react-icons/bs"
import Aos from 'aos';
import 'aos/dist/aos.css';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "../components/spinner"
import ReCAPTCHA from "react-google-recaptcha";

import Validations from "../components/validations"
import { Helmet } from "react-helmet";
// import Nodemailer from "nodemailer"



const Contact = () => {



  const [btnDisable, setBtnDisable] = useState(false)
  // const [emailDetails,setEmailDetails] = useState({name:"",email:"",phone: "",addinfo: ""})
  // const [validError,setValidError] = useState({
  //   name: "",
  //   email: "",
  //   phone: ""
  // })

  function onChange(value) {
    console.log("Captcha value:", value);
    setBtnDisable(true)
  }
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string()
   .required("Phone Number is required")
   .matches(
     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
     "Only Phone and Numbers Characters are accept e:g(-)"
   ).min(7).max(13),
   addinfo: yup.string().required("Additional Information is required").max(120)

  });

  const { register, handleSubmit, formState: { errors,isSubmitting }, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const sendEmail = async (formdata) => {
    formdata.subject = "New Contact Message"

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formdata)
      
  };
 console.log(formdata)
  const response = await fetch('https://api.edgeq.io/contact', requestOptions);
  const data = await response.json();


  

  if(data.success){
  toast("Form submitted successfully",{
    position: "top-center",
  autoClose: false,
  hideProgressBar: true,      
  closeOnClick: true,
})




// setMainLoader(false)

}


reset()
 }
  

  
    
    
   
    
    
//   }
  
  React.useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return(

  <Layout>

    <Seo title="EdgeQ | Contact"  description="Contact Us Get In Touch With Us Contact Us LOCATIONS HEADQUARTERS2550 Great America Way, Suite 125,Santa Clara, CA 95054 OTHER OFFICESSan Diego, California Bangalore, India CONTACT DETAILS Info@old.edgeq.io+1 (408) 430-2455 SOCIAL MEDIA Linkedin-in Twitter Youtube Send Us A Message"/>
    <Helmet>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Contact - EdgeQ" />
    <meta property="og:description" content="Contact Us Get In Touch With Us Contact Us LOCATIONS HEADQUARTERS2550 Great America Way, Suite 325,Santa Clara, CA 95054 OTHER OFFICESSan Diego, California Bangalore, India CONTACT DETAILS Info@old.edgeq.io+1 (408) 430-2455 SOCIAL MEDIA Linkedin-in Twitter Youtube Send Us A Message" />
    <meta property="og:url" content="https://edgeq.io/contact/" />
    <meta property="og:site_name" content="EdgeQ" />
    <meta property="article:modified_time" content="2021-11-08T16:17:08+00:00" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:label1" content="Est. reading time" />
    <meta name="twitter:data1" content="1 minute" />
    </Helmet>
    <ToastContainer hideProgressBar={true}/>
    <section className="contact-first-sec">
      <Container>
        <Row>
          <Col>
            <div className="contact-heading">
              <h2 data-aos="fade-down" >drop us a line</h2>
            </div>
            <div className="contact-sec">
              <Row>
                <Col md={7}>
                  <Row>
                    <Col xs={2}>
                      <div className="location-icon">
                        <MdOutlineLocationOn />
                      </div>
                    </Col>
                    <Col xs={10}>
                      <div className="location-text">
                        <h3>Edge Headquarters</h3>
                        <a
                          target="_blank"
                          href="https://goo.gl/maps/yNDwbjVVsLhhcWg9A"
                          rel="noreferrer"
                        >
                          2550 Great America Way, Suite 125, <br />
                          Santa Clara, CA 95054
                        </a>
                        <div className="other-off">
                          <h4>Other Offices</h4>
                          <a
                            target="_blank"
                            href="https://g.page/smartworks-coworking-bangalore?share"
                            rel="noreferrer"
                          >
                            No.93, Sargod Imperial, Field Marshal Cariappa Road,
                            Shanthala Nagar, Ashok Nagar, Bangalore-560025
                          </a>
                        </div>
                        <div className="other-off">
                          {/* <h4>EdgeQ Greece</h4> */}
                          <br/>
                          <a
                            target="_blank"
                            href="https://goo.gl/maps/piULSHbUrCzPwuGt7"
                            rel="noreferrer"
                          >
                            Marathonodromou 83,<br/>
                            Marousi 151 25 
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <form onSubmit={handleSubmit(sendEmail)} className="Contact-form" >
                    <label>
                      <input type="text"
                       name="name"
                       placeholder="Name"
                       {...register("name")}
                      
                       style={{borderColor: errors.name?.message ? "#ff0000": "#A0A0A0"}} />
                          <span>{<p className ="errors_valid" style={{color: "#ff0000"}}>{errors.name?.message}</p>} </span>
                    </label>
                    <label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        {...register("email")}
                      
                   
                        style={{borderColor: errors.email?.message ? "#ff0000": "#A0A0A0"}}
                        
                      />
                      <span>{<p className ="errors_valid" style={{color: "#ff0000"}}>{errors.email?.message}</p>} </span>
                    </label>
                    <label>
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Phone"
                        {...register("phone")}
                      
                     
                        style={{borderColor: errors.phone?.message ? "#ff0000": "#A0A0A0"}}
                        minLength={7}
                        maxLength={13}
                        
                      />
                      <span>{<p className ="errors_valid" style={{color: "#ff0000"}}>{errors.phone?.message}</p>} </span>
                    </label>
                    <label>
                      <textarea
                        type="text"
                        name="addinfo"
                        placeholder="Additional Info"
                        {...register("addinfo")}
                        style={{borderColor: errors.addinfo?.message ? "#ff0000": "#A0A0A0"}}
                      
                      
                        
                      />
                      <span>{<p className ="errors_valid" style={{color: "#ff0000"}}>{errors.addinfo?.message}</p>} </span>
                    </label>
                    <ReCAPTCHA
                    
                      sitekey="6LdCZRAfAAAAAG2vMFzYPVI9ez7_r0PF34cFemY0"
                      onChange={onChange}
                    />
                    <div className="form-btn" style={{justifyContent: "flex-end"}}>
                      {isSubmitting && <Spinner/>}
                      {/* className={ btnDisable === false ? '  btnDisabled': 'Not Done'} */}
                      <button type="submit">
                        <span className="arowup">
                          <BsArrowUp />
                        </span>{" "}
                        Send
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="contact-second-sec">
      <Container>
        <Row>
          <Col xs={6} md={6} className="Info_line">
            <div className="contact-info">
              <h3>Send a Message</h3>
              <a href="mailto:info@edgeq.io">info@edgeq.io</a> 
            </div>
          </Col>
          {/* <Col xs={6} md={4}>
            <div className="contact-info">
              <h3>Talk to Us</h3>
              <a href="tel:14084302455">+1 (408) 430-2455</a>
            </div>
          </Col> */}
          <Col xs={6} md={6}>
            <div className="contact-info last">
              <h3>Get in Touch</h3>
              <ul className="social-icons">
                <li>
                  <a
                    href="https://www.linkedin.com/company/edgeq"
                    target="_blank"
                    rel=" noreferrer"
                  >
                    <AiFillLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/edgeq_inc?lang=en"
                    target="_blank"
                    rel=" noreferrer"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg"
                    target="_blank"
                    rel=" noreferrer"
                  >
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)
        }

export default Contact
